<template>
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="80px"
    >
      <div class="wrap">
        <div class="left">
          <el-form-item label="" prop="">
            <sale-area
              ref="saleArea"
              @set-sale-select-id="areaSelect"
            ></sale-area>
          </el-form-item>
          <el-form-item label="" prop="depart_id">
            <el-select
              v-model="form.depart_id"
              clearable
              style="width: 140px"
              placeholder="选择部门"
            >
              <el-option
                v-for="(i, idx) in groupSelect"
                :key="idx"
                :value="i.id"
                :label="i.text"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="user_id">
            <el-select
              v-model="form.user_id"
              clearable
              style="width: 140px"
              placeholder="选择业务员"
            >
              <el-option
                v-for="(i, idx) in staffList"
                :key="idx"
                :value="i.id"
                :label="i.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="查询时段:" prop="time">
            <el-date-picker
              v-model="time"
              type="daterange"
              range-separator="-"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 210px"
            ></el-date-picker>
          </el-form-item>
          <div></div>
          <el-form-item label="" prop="">
            <sale-area
              ref="saleArea2"
              :place="'选择对比区域'"
              @set-sale-select-id="areaSelect2"
            ></sale-area>
          </el-form-item>
          <el-form-item label="" prop="eq_depart_id">
            <el-select
              v-model="form.eq_depart_id"
              clearable
              style="width: 140px"
              placeholder="选择对比部门"
            >
              <el-option
                v-for="(i, idx) in groupSelect"
                :key="idx"
                :value="i.id"
                :label="i.text"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="eq_user_id">
            <el-select
              v-model="form.eq_user_id"
              clearable
              style="width: 140px"
              placeholder="选择对比业务员"
            >
              <el-option
                v-for="(i, idx) in staffList"
                :key="idx"
                :value="i.id"
                :label="i.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="对比时段:" prop="time2">
            <el-date-picker
              v-model="time2"
              type="daterange"
              range-separator="-"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 210px"
            ></el-date-picker>
          </el-form-item>
        </div>
        <div class="right">
          <el-form-item label="" prop="">
            <brand-select
              ref="brandSelect"
              :place="'全部品牌'"
              style="width: 140px"
              @brand-select-change="brandSelectId"
            ></brand-select>
          </el-form-item>
          <el-form-item label="" prop="">
            <el-button type="primary" @click="handleQuery">查询</el-button>
          </el-form-item>
          <el-form-item label="" prop="">
            <el-button>自定义查询商品组</el-button>
          </el-form-item>
          <el-form-item label="" prop="">
            <el-button @click="handleExport">导出</el-button>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      >
        <template
          v-if="
            item.label == '查询目标终端总数' ||
            item.label == '查询目标销售家数' ||
            item.label == '查询目标铺市率'
          "
          slot="header"
        >
          <h3 style="color: red">{{ item.label }}</h3>
        </template>
        <template #default="{ row }">
          <div
            v-if="
              item.label == '查询目标销售家数' ||
              item.label == '对比目标销售家数'
            "
            @click="handlermarker(row, item.label)"
          >
            <span class="border">{{ row[item.prop] }}</span>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <marker-div ref="markercom" />
  </div>
</template>
<script>
  import _ from 'lodash'
  import saleArea from '@/baseComponents/saleAreaSelectTree'
  import brandSelect from '@/baseComponents/brandSelect'
  import { departmentList } from '@/api/performance'
  import { getStaffList } from '@/api/setPrice'
  import { inClassList } from '@/api/report'
  import { day_30 } from '@/utils/Time'
  import { downloadFile } from '@/api/Employee'
  import markerDiv from '@/views/project/report/analysis/inBrand/components/marker'
  export default {
    name: 'InClass',
    components: { saleArea, brandSelect, markerDiv },
    data() {
      return {
        loading: false,
        groupSelect: [],
        staffList: [],
        time: [],
        time2: [],
        form: {
          sort: '', //
          order: '', //
          start_time: '', //
          end_time: '', //
          area_id: '', //区域
          depart_id: '', //部门
          user_id: '', //业务
          brand_id: '', //品牌
          eq_start_time: '', //对比
          eq_end_time: '', //
          eq_area_id: '', //
          eq_depart_id: '', //
          eq_user_id: '', //
          eq_brand_id: '', //
        },
        list: [],
        checkList: [
          '商品名称',
          '品牌',
          '规格',
          '查询目标终端总数',
          '对比目标终端总数',
          '查询目标销售家数',
          '对比目标销售家数',
          '查询目标铺市率',
          '对比目标铺市率',
          // '比较幅度',
        ],
        columns: [
          {
            order: 1,
            label: '商品名称',
            prop: 'goods_name',
            width: '',
          },
          {
            order: 2,
            label: '品牌',
            prop: 'brand_name',
            width: '',
          },
          {
            order: 3,
            label: '规格',
            prop: 'specs',
            width: '',
          },
          {
            order: 4,
            label: '查询目标终端总数',
            prop: 'total_num',
            width: '',
          },
          {
            order: 5,
            label: '对比目标终端总数',
            prop: 'eq_total_num',
            width: '',
          },
          {
            order: 6,
            label: '查询目标销售家数',
            prop: 'num',
            width: '',
          },
          {
            order: 7,
            label: '对比目标销售家数',
            prop: 'eq_num',
            width: '',
          },
          {
            order: 8,
            label: '查询目标铺市率',
            prop: 'rate',
            width: '',
          },
          {
            order: 9,
            label: '对比目标铺市率',
            prop: 'eq_rate',
            width: '',
          },
          // {
          //   order: 10,
          //   label: '比较幅度',
          //   prop: 'eq_range',
          //   width: '',
          // },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
      time2(val) {
        if (val) {
          this.form.eq_start_time = val[0]
          this.form.eq_end_time = val[1]
        } else {
          this.form.eq_start_time = ''
          this.form.eq_end_time = ''
        }
      },
    },
    created() {
      this.initTime()
      this.fetchData()
      // 获取业务员下拉
      getStaffList().then((res) => {
        if (res.code == 200) this.staffList = res.data
      })
      // 部门下拉
      departmentList().then((res) => {
        this.groupSelect = res.data.list
      })
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, code, msg, totalCount } = await inClassList(this.form)
        console.log(data)
        // this.total = totalCount
        this.list = data
        this.loading = false
      },
      handlermarker(row, label) {
        if (label == '查询目标销售家数') {
          this.$refs.markercom.form = {
            ...this.$refs.markercom.form,
            start_time: this.form.start_time, //
            end_time: this.form.end_time, //
            area_id: this.form.area_id, //区域
            depart_id: this.form.depart_id, //部门
            user_id: this.form.user_id, //业务
            goods_id: row.goods_id,
          }
        } else {
          this.$refs.markercom.form = {
            ...this.$refs.markercom.form,
            start_time: this.form.eq_start_time, //
            end_time: this.form.eq_end_time, //
            area_id: this.form.eq_area_id, //区域
            depart_id: this.form.eq_depart_id, //部门
            user_id: this.form.eq_user_id, //业务
            goods_id: row.goods_id,
          }
        }
        this.$refs.markercom.handlergoodsinfo()
        this.$refs.markercom.detail = 2
        this.$refs.markercom.showDialog = true
      },
      handleQuery() {
        this.fetchData()
      },
      areaSelect(val) {
        this.form.area_id = val
      },
      areaSelect2(val) {
        this.form.eq_area_id = val
      },
      brandSelectId(val) {
        this.form.brand_id = val
      },
      initTime() {
        this.time = day_30()
        this.time2 = day_30()
        this.form.start_time = this.time[0]
        this.form.end_time = this.time[1]
        this.form.eq_start_time = this.time[0]
        this.form.eq_end_time = this.time[1]
      },
      handleExport() {
        downloadFile(
          '/reportAdmin/analysis/market-goods-list-export',
          '铺市率按商品.xlsx',
          this.form
        )
      },
    },
  }
</script>
<style lang="scss" scoped>
  .wrap {
    display: flex;
    flex-wrap: nowrap;
    .left {
      width: 93%;
    }
  }
  .border {
    border-bottom: red 2px solid;
    cursor: pointer;
  }
</style>
